<template>
	<div>
		<div class="crumbs">
			<el-breadcrumb separator="/">
				<el-breadcrumb-item>
					<i class="el-icon-lx-cascades"></i> 滚动图修改
				</el-breadcrumb-item>
			</el-breadcrumb>
		</div>
		
		
		<div class="container">
			<!-- 首页滚动图1280*600 -->
			<div class="handle-box">
				<el-button type="primary" icon="el-icon-search" @click="getList">刷新</el-button>
				<el-button type="primary" icon="el-icon-edit" @click="editVisible = true">插入</el-button>
			</div>
			<el-table
				:data="tableData"
				border
				class="table"
				ref="multipleTable"
				header-cell-class-name="table-header"
				@selection-change="handleSelectionChange"
			>
				<el-table-column prop="id" label="ID" width="55" align="center"></el-table-column>
				<el-table-column  label="图片">
					
					<template #default="scope">
						<span style="display: flex; justify-content: center;"><img :src="scope.row.pic" style="width: 100px; height: 80px;" /></span>
						
					</template>
				</el-table-column>
				<el-table-column prop="ImgName" label="图片名称"></el-table-column>
				
				<el-table-column label="操作" width="180" align="center">
					<template #default="scope">
					<!-- <el-button
							type="text"
							icon="el-icon-edit"
							@click="handleEdit(scope.$index, scope.row)"
						>编辑</el-button> -->
					<el-button
					type="text"
					icon="el-icon-delete"
					class="red"
					@click="handleDeleteItem(scope.$index, scope.row)"
					>删除</el-button>
					
		
					</template>
					
				</el-table-column>
			</el-table>
			
		</div>
		
		

		<!-- 	<el-form ref="form"  label-width="170px">
				<el-form-item label="首页滚动图1280*600">
					<el-upload
					ref="ImgUpload1"
					:action="ImgUploadPath + 'lunbo1.jpg'"
					list-type="picture-card"
					:limit="1"
					:auto-upload="false"
					:on-success="ImgUploadSuccess1"
					:on-error="ImgUploadError1">
					<i class="el-icon-plus"></i>
					</el-upload>
					
				</el-form-item>
				<p style="text-align: right;">
					<el-button type="primary" @click="onSubmitPic1">立即修改</el-button>
				</p>
				
				
			</el-form> -->
		
		
		<!-- 插入弹出框 -->
		<el-dialog title="插入" v-model="editVisible" width="60%">
			<el-form ref="form" :model="form" label-width="200px">
				
				<el-form-item label="首页滚动图1280*600 推荐jpg">
					<!-- 图片上传操作 -->
					<el-upload
					ref="ImgUpload1"
					:action="ImgUploadPath"
					list-type="picture-card"
					:limit="1"
					:file-list="ImgUploadList1"
					:auto-upload="false"
					:on-success="ImgUploadSuccess"
					:on-error="ImgUploadError">
					<i class="el-icon-plus" ></i>
					</el-upload>
					
				</el-form-item>
				
			</el-form>
			<template #footer>
				<span class="dialog-footer">
					<el-button @click="editVisible = false">取 消</el-button>
					<el-button type="primary" @click="onSubmitPic1">确 定</el-button>
				</span>
			</template>
		</el-dialog>
		
		
		
	</div>
	
</template>

<script>
	//import {  setCookie } from "../../js/Took.js"
	import {getUserData} from '../../js/User.js'   //获取用户信息
	import {getuploadImgFengMianPath,getGunDonTuData,deleteGunDonTuData} from '../../api/index.js'   //接口
	import {serverPathMain} from '../../api/config.js'
	export default {
		name: "GunDonPicEdit",
		data() {
			return {
				tableData:[],
				UserData:{},  //后台登录人的用户信息
				editVisible:false,  //插入窗口显示
				ImgUploadList1:[],
				ImgUploadPath:'',
				addFilesDate:'lunbo1.jpg',
			}
		},
		mounted() {
			this.UserData = getUserData();
			console.log(this.UserData.username);
		//	setCookie("UserId",this.UserData.username,365);  //保存UserId  这里的UserId是标识
			this.ImgUploadPath = getuploadImgFengMianPath(this.UserData.username);
			
			
			this.getList();
		},
		methods: {
			getList:function(){
				getGunDonTuData(this.UserData.username).then(res=>{
					res.map(e=>{
						e.pic = serverPathMain + "/other/lunboPic/" + e.ImgName;
					})
					this.tableData = res; 
				})
			},
			onSubmitPic1:function(){  //首页滚动图提交
				this.$refs.ImgUpload1.submit();
				this.$refs.ImgUpload1.clearFiles();
				this.editVisible = false;
				this.getList();
			},
			
			handleDeleteItem(index, row){   //点击删除按钮
				this.idx = index;
				this.form = row;
				deleteGunDonTuData(this.form.id).then(res=>{
					console.log(res);
					this.$message.success("删除成功");
					this.getList();
				})
				
			},
			
			ImgUploadSuccess1(file, fileList){
				console.log(file);
				console.log(fileList);
				console.log("参观参观刚才");
			}
		}
	};
	
</script>

<style>
	.handle-box {
		margin-bottom: 20px;
	}
	.red {
		color: #ff0000;
	}
</style>


