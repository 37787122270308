<template>
	<!-- 分身的合伙人用户管理 -->
	<div>
		<div class="crumbs">
			<el-breadcrumb separator="/">
				<el-breadcrumb-item>
					<i class="el-icon-lx-cascades"></i> 分身用户
				</el-breadcrumb-item>
			</el-breadcrumb>
		</div>
		
		<div class="container">
			<div class="handle-box">
				<el-button type="primary" icon="el-icon-search" @click="getList">刷新</el-button>
				<el-button type="primary" icon="el-icon-plus" @click="showAddDialog()">添加</el-button>
			</div>
			<el-table
				:data="tableData"
				border
				class="table"
				ref="multipleTable"
				header-cell-class-name="table-header"
				@selection-change="handleSelectionChange"
			>
				<el-table-column prop="id" label="ID" width="55" align="center"></el-table-column>
				<el-table-column prop="UserId" label="会员号"></el-table-column>
				<el-table-column prop="upass" label="密码"></el-table-column>
				<el-table-column prop="address" label="分身地址"></el-table-column>
				
				<el-table-column label="操作" width="180" align="center">
					<template #default="scope">
					<el-button
					type="text"
					icon="el-icon-delete"
					class="red"
					@click="handleDeleteItem(scope.$index, scope.row)"
					>删除</el-button>
					

					</template>
					
				</el-table-column>
			</el-table>
			<div class="pagination">
				<el-pagination
					background
					layout="total, prev, pager, next"
					:current-page="query.pageIndex"
					:page-size="query.pageSize"
					:total="pageTotal"
					@current-change="handlePageChange"
				></el-pagination>
			</div>

		</div>
		
		
		
		<!-- 添加用户弹出框 -->
		<el-dialog title="添加" v-model="addVisible" width="40%">
			<el-form ref="form"  label-width="70px">
				<el-form-item label="会员号">
					<el-input v-model="addDialog.UserId" placeholder="请输入会员号 必须唯一" ></el-input>
				</el-form-item>
				<el-form-item label="用户密码">
					<el-input v-model="addDialog.upass" placeholder="用户登录后台时的密码"></el-input>
				</el-form-item>

				
			</el-form>
			<template #footer>
				<span class="dialog-footer">
					<el-button @click="addVisible = false">取 消</el-button>
					<el-button type="primary" @click="AddUser">确 定</el-button>
				</span>
			</template>
		</el-dialog>
		
	</div>
</template>

<script>
	import {getFenshenUserList,AddFenshenUser,DeleteFenshenUser} from '../../api/index.js'
	export default {
		name: "FengShengUser",
		created(){
			this.getList();
		},
		data() {
			return {
			
				query: {
					UserId: "",   //用户Id
					pageIndex: 1,
					pageSize: 10
				},
				pageTotal: 0,
				tableData: [],
				addVisible:false   ,//添加用户窗口显示
				addDialog:{  //添加用户窗口
					UserId:'',  
					upass:''   //密码
				}
			}
		},
		methods:{
			showAddDialog(){  //显示添加窗口
				this.addDialog.UserId = '';
				this.addDialog.upass = '';
				this.addVisible = true;
			},
			
			handleDeleteItem(index,row){
				this.DeleteUser(row.id);
			},
			
			getList(){
				getFenshenUserList().then(res=>{   //获取分身用户列表
					console.log(res);
					res.map(e=>{
						e.address = 'pages/index/index?UserId=' + e.UserId + '&isFm=true';
					})
					this.tableData = res;
					this.pageTotal = res.length;
				})
			},
			AddUser(){
				if(this.addDialog.upass.length < 6){
					this.$message.error('密码长度不能少于6位');
					return;
				}
				
				AddFenshenUser(this.addDialog.UserId,this.addDialog.upass).then(res=>{
					console.log(res);
					if (res.result == 1) {
						this.$message.success('添加成功');
						this.getList();  //刷新一下
					} else if(res.result == 2){
						this.$message.error('已经添加过了');
					} else if(res.result == 3){
						this.$message.error('没有这个用户');
					}else{
						this.$message.error('错误');
					}
					this.addVisible = false;
				})
			},
			DeleteUser(id){
				DeleteFenshenUser(id).then(res=>{
					if (res.result == 1) {
						this.$message.success('删除成功');
						this.getList();  //刷新一下
					} else{
						this.$message.error('失败');
					} 
				})
			}
			
			
		}
	}		
</script>

<style>
	.handle-box {
		margin-bottom: 20px;
	}

	.handle-select {
		width: 120px;
	}

	.handle-input {
		width: 200px;
		display: inline-block;
	}
	.table {
		width: 100%;
		font-size: 14px;
	}
	.red {
		color: #ff0000;
	}
	.green{
		color: #2ea70d;
	}

	.mr10 {
		margin-right: 10px;
	}
	.table-td-thumb {
		display: block;
		margin: auto;
		width: 40px;
		height: 40px;
	}
</style>
