<template>
	<div>
		<div class="crumbs">
			<el-breadcrumb separator="/">
				<el-breadcrumb-item>
					<i class="el-icon-lx-cascades"></i> 客服二维码修改
				</el-breadcrumb-item>
			</el-breadcrumb>
		</div>
		
		<div class="container">
			<el-form ref="form"  label-width="170px">
				<el-form-item label="客服二维码760*1080">
					<!-- 图片上传操作 -->
					<el-upload
					ref="ImgUpload1"
					:action="ImgUploadPath"
					list-type="picture-card"
					:limit="1"
					:auto-upload="false"
					:on-success="ImgUploadSuccess1"
					:on-error="ImgUploadError1">
					<i class="el-icon-plus"></i>
					</el-upload>
				</el-form-item>
				<p style="text-align: right;">
					<el-button type="primary" @click="onSubmitPic1">立即修改</el-button>
				</p>
			</el-form>
		
		</div>
		
	</div>
	
</template>

<script>
	import {getUserData} from '../../js/User.js'   //获取用户信息
	import {getuploadServiceImg} from '../../api/index.js'   //接口
	export default {
		name: "ServiceEdit",
		data() {
			return {
				ImgUploadList1:[],
				ImgUploadList2:[],
				ImgUploadPath:'',
				addFilesDate:'lunbo1.jpg',
			}
		},
		mounted() {
			this.UserData = getUserData();
			
			this.ImgUploadPath = getuploadServiceImg(this.UserData.username);
		},
		methods: {
			onSubmitPic1:function(){  //首页滚动图提交
				this.$refs.ImgUpload1.submit();
			},
			ImgUploadSuccess1(file, fileList){
				console.log(file);
				console.log(fileList);
			}
		}
	};
	
</script>

<style>
	
</style>


