<template>
    <div>
        <div class="crumbs">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item>
                    <i class="el-icon-lx-cascades"></i> 新闻发布
                </el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="container">
            <div class="handle-box">
				<el-button type="primary" icon="el-icon-search" @click="getData">刷新</el-button>
				<el-button type="primary" icon="el-icon-edit" @click="editVisible = true">插入</el-button>
            </div>
            <el-table
                :data="tableData"
                border
                class="table"
                ref="multipleTable"
                header-cell-class-name="table-header"
                @selection-change="handleSelectionChange"
            >
                <el-table-column prop="id" label="ID" width="55" align="center"></el-table-column>
                <el-table-column prop="title" label="标题"></el-table-column>
                <el-table-column prop="date" label="发布时间"></el-table-column>
                <el-table-column prop="imgName" label="图片名称"></el-table-column>
                <el-table-column prop="filePath" label="文件地址"></el-table-column>
				
                <el-table-column label="操作" width="180" align="center">
                    <template #default="scope">
					<!-- <el-button
							type="text"
							icon="el-icon-edit"
							@click="handleEdit(scope.$index, scope.row)"
						>编辑</el-button> -->
					<el-button
					type="text"
					icon="el-icon-delete"
					class="red"
					@click="handleDeleteItem(scope.$index, scope.row)"
					>删除</el-button>
					

                    </template>
					
                </el-table-column>
            </el-table>
            <div class="pagination">
                <el-pagination
                    background
                    layout="total, prev, pager, next"
                    :current-page="query.pageIndex"
                    :page-size="query.pageSize"
                    :total="pageTotal"
                    @current-change="handlePageChange"
                ></el-pagination>
            </div>
        </div>


		

		<!-- 插入弹出框 -->
		<el-dialog title="插入" v-model="editVisible" width="60%">
			<el-form ref="form" :model="form" label-width="70px">
				<el-form-item label="标题">
					<el-input v-model="InputTitle"></el-input>
				</el-form-item>
				<el-form-item label="文件地址">
					<el-input v-model="InputFileName" style="width: 80%;"></el-input>
					<el-button style="margin-left: 10px;" type="primary" @click="SearchFileNamePath='';SearchFile();" icon="el-icon-search"></el-button>
				</el-form-item>
				
				<el-form-item label="图片封面">
					<!-- 图片上传操作 -->
					<el-upload
					ref="ImgUpload"
					:action="UploadPath"
					list-type="picture-card"
					:limit="1"
					:file-list="ImgUploadList"
					:auto-upload="false"
					:on-success="ImgUploadSuccess"
					:on-error="ImgUploadError">
					<i class="el-icon-plus"></i>
					</el-upload>
					
				</el-form-item>
				
				
				
			</el-form>
			<template #footer>
				<span class="dialog-footer">
					<el-button @click="editVisible = false">取 消</el-button>
					<el-button type="primary" @click="saveInsertData">确 定</el-button>
				</span>
			</template>
		</el-dialog>
		
		<el-dialog title="选择文件" v-model="SelectFileNameVisible" width="30%">
			<el-button type="primary" @click="FileListShangYiJi">上一级</el-button>
			<el-table :data="FileNameList"
				@row-click="handleFileNameSelectionChange"
			>
				<el-table-column width="500" property="fileName" label="文件名称"></el-table-column>
			</el-table>
			<template #footer>
				<span class="dialog-footer">
					<el-button @click="SelectFileNameVisible = false">取 消</el-button>
					<el-button type="primary" @click="FileSelectfolder">确 定</el-button>
				</span>
			</template>
		</el-dialog>
		
    </div>
</template>

<script>
import { getNewsData,deleteNewsItem,AdmingetFileNameList,getAdminuploadImgFilePath} from "../../api/index";
export default {
    name: "news",
    data() {
        return {
            query: {
                UserId: "",   //用户Id
                pageIndex: 1,
                pageSize: 10
            },
			dialogImageUrl: '',
			dialogVisible: false,
            tableData: [],
            editVisible: false,  //修改数据窗口显示状态
			SelectFileNameVisible:false,  //文件选择窗口
            pageTotal: 0,
            form: {},
            idx: -1,
            id: -1,
			UploadPath:'',  //上传地址
			ImgUploadList:[], //上传图片的列表
			SearchFileNamePath:'',  // 文件搜索的path
			InputFileName:'' ,//输入框的文件地址名称
			InputTitle:'',  // 输入的标题
        };
    },
    created() {
        this.getData();
    },
    methods: {
        // 获取 easy-mock 的模拟数据
        getData() {
            getNewsData(this.query.pageIndex).then(res => {
                console.log(res);
				console.log(res);
				res = JSON.parse(res);  //
				
				var list_data = res.data;


				this.tableData = list_data;
                this.pageTotal = res.total || res.data.length;
            });
        },
		SearchFile:function(fileName){
			const temp_loading = this.$loading({
				lock: true,
				text: '加载中',
				spinner: 'el-icon-loading',
				background: 'rgba(0, 0, 0, 0.7)'
			});
			AdmingetFileNameList(fileName).then(res=>{
				temp_loading.close();  //关闭加载动画
				console.log(res);
				var t_fileList = [];
				res.filearr.map(datas=>{
					t_fileList.push({fileName:datas});
				})
				console.log(t_fileList);
				
					this.FileNameList = t_fileList;
					this.SelectFileNameVisible=false;
				this.$nextTick(()=>{
					this.SelectFileNameVisible=true;
				})
				
			})
		},
        // 触发搜索按钮
        handleSearch() {
			this.query.pageIndex = 1;
            this.getData();
        },
		handleFileNameSelectionChange(row, event, column){  //选择文件名称 时 每次单击表格触发
			console.log(row);
			var t_fileName = row.fileName;
			
			var last_weizhi = t_fileName.substr(t_fileName.length - 4);
			if(last_weizhi == '.pdf'){  //找到.这个符号 并且位于 末尾位置6以内
				this.InputFileName = this.SearchFileNamePath + '/' + t_fileName; //文件地址
				this.SelectFileNameVisible=false;  //关闭选择窗口
				return;
			}
			
			if(this.SearchFileNamePath == ''){
				this.SearchFileNamePath = t_fileName;
			}else{
				this.SearchFileNamePath += '/' + row.fileName;
			}
			
			this.SearchFile(this.SearchFileNamePath);
			console.log(event);
			console.log(column);
		},
		FileListShangYiJi(){   //文件列表上一级
			this.SearchFileNamePath = this.SearchFileNamePath.substr(0,this.SearchFileNamePath.lastIndexOf('/'));
			this.SearchFile(this.SearchFileNamePath);
		},
		FileSelectfolder(){   //文件选择文件夹目录
			this.InputFileName = this.SearchFileNamePath; //文件夹地址
			this.SelectFileNameVisible=false;  //关闭选择窗口
		},
		handleDeleteItem(index, row){   //点击删除按钮
			this.idx = index;
			this.form = row;
			deleteNewsItem(this.form.id).then(res=>{
				console.log(res);
				this.$message.success("删除成功");
				this.getData();
			})
			
		},
		saveInsertData:function(){  //保存插入一行数据
			this.UploadPath = getAdminuploadImgFilePath(this.InputTitle,this.InputFileName);
			this.$nextTick(()=>{
				this.$refs.ImgUpload.submit();
			})
		
			// AdminuploadImgFile('','',).then(res=>{
			// 	console.log(res);			
			// })
		},
		ImgUploadSuccess:function(response, file, fileList){  //图片文件上上传成功
			console.log(response);
			console.log(file);
			console.log(fileList);
			this.$message.success('添加成功');
			//上传成功后下面初始化
			this.editVisible = false;   //隐藏窗口
			this.InputTitle = '';  //标题清空
			this.InputFileName = '';   //文件地址清空
			this.ImgUploadList = [];  //上传图片的列表清空
			
		},
		ImgUploadError:function(err, file, fileList){  //图片文件上传失败
			console.log(err);
			console.log(file);
			console.log(fileList);
			this.$message.error('上传文件失败,请重试');
		},
        // 分页导航
        handlePageChange(val) {
			this.query.pageIndex = val;
            this.getData();
        }
    }
};
</script>

<style scoped>
.handle-box {
    margin-bottom: 20px;
}

.handle-select {
    width: 120px;
}

.handle-input {
    width: 200px;
    display: inline-block;
}
.table {
    width: 100%;
    font-size: 14px;
}
.red {
    color: #ff0000;
}
.green{
	color: #2ea70d;
}

.mr10 {
    margin-right: 10px;
}
.table-td-thumb {
    display: block;
    margin: auto;
    width: 40px;
    height: 40px;
}
</style>
